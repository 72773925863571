import {
  getRandomDarkHexColor,
  getRandomLightHexColor,
} from "../../utils/color";

function getColorFromIndex(index) {
  if (index < 0 || index > 100) {
    return;
  }

  // Map the index to a color range
  if (index < 20) {
    return "rgb(234, 57, 67)"; // Fear (low index)
  } else if (index >= 20 && index < 40) {
    return "rgb(234, 140, 0);"; // Neutral (medium index)
  } else if (index >= 40 && index < 60) {
    return "rgb(243, 212, 47)"; // Neutral (medium index)
  } else if (index >= 60 && index < 80) {
    return "rgb(147, 217, 0)"; // Neutral (medium index)
  } else {
    return "rgb(22, 199, 132)"; // Greed (high index)
  }
}

const IndexRender = (props) => {
  const { infos } = props || {};

  return (
    <div
      className="flex flex-wrap justify-evenly items-center"
      style={{
        backgroundColor: "#001524",
      }}
    >
      {infos?.map((info, key) => {
        return (
          <div
            key={key}
            className="flex items-center justify-center flex-col p-3 m-2"
          >
            <h4 className="text-white text-center">{info?.title}</h4>
            <div
              style={{
                backgroundColor: getRandomLightHexColor(),
                width: 128,
                height: 128,
                borderRadius: 64,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="text-black text-center text-3xl">
                {info?.value}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default IndexRender;
