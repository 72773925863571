import moment from "moment";
import { GAS_FEE_MILE, SCHEDULE } from "./constants";

const GasFeePlanComponent = (props) => {
  const { action } = props || {};
  const renderData = (props, key) => {
    const { title, data } = props || {};
    return (
      <div
        key={key}
        style={{
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: 8,
        }}
      >
        <h5 style={{ fontWeight: "bold", color: "#007B91" }}>{title}</h5>

        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            // justifyContent: "space-evenly",
            alignItems: "flex-start",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {data?.map((note, key) => {
            return <div key={key}> - {note}</div>;
          })}
        </div>
      </div>
    );
  };

  function getActivityAtTime() {
    const currentDate = moment().format("YYYY-MM-DD"); // Lấy ngày hiện tại
    for (const event of SCHEDULE) {
      const startTime = moment(
        `${currentDate}T${event.start}`,
        `YYYY-MM-DDTHH:mm`
      );
      const endTime = moment(`${currentDate}T${event.end}`, `YYYY-MM-DDTHH:mm`);
      if (moment().isBetween(startTime, endTime, null, "[]")) {
        return event;
      }
    }
    return "Không có hoạt động đề xuât";
  }
  const recommendActivity = getActivityAtTime();

  return (
    <div className="flex flex-col items-center justify-center bg-white p-4">
      <h3 className="font-bold">{`${action?.label} HƠN (${GAS_FEE_MILE})`}</h3>
      <h6 className="font-bold text-center">
        <span>
          Khung giờ <span className="text-red-600">{moment().hours()}h</span>{" "}
          tuyệt vời cho{" "}
          <span className="text-blue-500">{recommendActivity?.activity}</span>
        </span>
      </h6>
      {recommendActivity?.notes && <h6>Notes: {recommendActivity?.notes}</h6>}
      <div className="bg-white flex justify-evenly items-center p-4">
        <div className="bg-white flex justify-center flex-col">
          {[
            {
              title: action.title,
              data: action?.actions,
            },
            {
              title: action.title,
              data: action?.preventActions,
            },
          ]?.map(renderData)}
        </div>
      </div>
    </div>
  );
};

export default GasFeePlanComponent;
