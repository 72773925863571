import React, { useEffect } from "react";
import {
  ScrollView,
  Box,
  VStack,
  Stack,
  Center,
  Heading,
  Divider,
  Image,
  Text,
  Pressable,
} from "native-base";
import NavigateMenu from "./NavigateMenu";
import { useNavigate } from "react-router-dom";
import { stackList } from "./stackConfig";
import { truncateMiddle } from "../utils/string";
// const caro = require("../assets/images/caro.png");
// https://github.com/nguyenquanit17/public-image/blob/master/test.png?raw=true

const HomeStack = () => {
  const navigate = useNavigate();

  const renderElement = ({ id, title, image, route, url }, index) => {
    const pressStack = () => {
      if (route) {
        navigate(route, { replace: false });
      }

      if (url) {
        window.location.href = url;
      }
    };
    return (
      <Pressable
        key={index}
        onPress={pressStack}
        bg="rose.100"
        _hover={{
          bg: "rose.300",
        }}
        rounded="xl"
        w={[132]}
        m={2}
      >
        <Center key={id} padding={2}>
          <Text
            textAlign="center"
            fontSize={["l"]}
            mb="2"
            style={{ color: "#000" }}
          >
            {truncateMiddle(title, 12, 0, "...")}
          </Text>
          <Image
            borderRadius={20}
            space={2}
            source={{ uri: image }}
            alt="Game image"
            minW="96px"
            minH="96px"
            resizeMode="contain"
          />
        </Center>
      </Pressable>
    );
  };

  const renderStack = ({ title, list }, key) => {
    return (
      <VStack key={key} mt="4" alignItems={"center"}>
        <Heading size="md">{title}</Heading>
        {/* <div className="flex w-[100] flex-auto flex-wrap bg-green-500 max-xl:-w"> */}
        <Stack
          p={2}
          direction={["row", "row", "row"]}
          flexWrap={"wrap"}
          style={
            {
              // backgroundColor: "blue",
            }
          }
        >
          {list.map(renderElement)}
        </Stack>
        {/* </div> */}

        <Divider />
      </VStack>
    );
  };

  return (
    <>
      <NavigateMenu />
      <Box flex="1">
        <div className="flex flex-col justify-center items-center">
          {stackList.filter((s) => s?.list?.length).map(renderStack)}
        </div>
      </Box>
    </>
  );
};

export default HomeStack;
