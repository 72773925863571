import axios from "axios";

export async function getFearOfGreedCryptoIndex() {
  try {
    const resAxios = await axios.get("https://api.alternative.me/fng/");
    return resAxios?.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getFearOfGreedIndex() {
  try {
    const res = await getFearOfGreedCryptoIndex();
    return res?.data[0];
  } catch (error) {
    console.log(error);
  }
}
