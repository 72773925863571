import {
  BrowserRouter,
  Switch,
  Route,
  Routes,
  Link,
  Redirect,
  useNavigate,
  useLocation,
  Outlet,
  Navigate,
} from "react-router-dom";
import { useLoginGoogle } from "../Provider/GoogleAuthContext";
import Login from "../screens/Login";
import Caro from "../components/Caro";
import Loto from "../components/Loto";
import WordPlay from "../components/WordPlay";
import CountDown from "../components/CountDown";
import HomeStack from "../screens/HomeStack";
import { stackList } from "../screens/stackConfig";
import AccessDenied from "../screens/AccessDenied";
import Challenge from "../components/Challenge";
// import GanttChart from "../components/Calendar/GanttChartComponent";
import Plan from "../components/Plan";
import CalendarComponent from "../components/Plan/Calendar/CalendarComponent";
import TripChecklist from "../components/TripChecklist";
import UploadFileComponent from "../components/uploads";

function MainRouter() {
  const countDownId = 2;
  const countDownStack = stackList.find((item) => item.id === countDownId);

  const createRoute = (countdown) => {
    return (
      <Route
        path={countdown.route}
        element={
          <RequireAuth>
            <CountDown {...countdown} />
          </RequireAuth>
        }
      />
    );
  };

  const createRouteAuth = ({ path, component }) => {
    return (
      <Route path={path} element={<RequireAuth>{component}</RequireAuth>} />
    );
  };

  // const routes = [
  //   {
  //     route: '/tuan-anh-countdown',
  //   }
  // ];

  return (
    <Routes>
      {/* <Route element={<Layout />}> */}
      <Route path="/login" element={<Login />} />
      <Route path="/access-denied" element={<AccessDenied />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <HomeStack />
          </RequireAuth>
        }
      />
      {countDownStack.list
        ?.filter((s) => s?.list?.length)
        ?.map((item) => {
          return createRoute(item);
        })}
      <Route
        path="/play-caro"
        element={
          <RequireAuth>
            <Caro />
          </RequireAuth>
        }
      />
      <Route
        path="/play-loto"
        element={
          <RequireAuth>
            <Loto />
          </RequireAuth>
        }
      />
      <Route
        path="/word-play"
        element={
          <RequireAuth>
            <WordPlay />
          </RequireAuth>
        }
      />
      <Route
        path="/challenge-list"
        element={
          <RequireAuth>
            <Challenge />
          </RequireAuth>
        }
      />

      <Route
        path="/calendar"
        element={
          <RequireAuth>
            <CalendarComponent />
          </RequireAuth>
        }
      />
      <Route
        path="/plan"
        element={
          <RequireAuth>
            <Plan />
          </RequireAuth>
        }
      />
      <Route
        path="/trip-checklist"
        element={
          // <RequireAuth>
          <TripChecklist />
          // </RequireAuth>
        }
      />
      <Route
        path="/uploads"
        element={
          <RequireAuth>
            <UploadFileComponent />
          </RequireAuth>
        }
      />
      {/* </Route> */}
    </Routes>
  );
}

export default MainRouter;

function RequireAuth({ children }) {
  let { user } = useLoginGoogle();
  let location = useLocation();

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

// function RequirePermission({ children }) {
//   let { user } = useLoginGoogle();
//   let location = useLocation();

//   if (!user?.permissions || !user?.permissions?.include("xomnhala")) {
//     return <Navigate to="/access-denied" state={{ from: location }} replace />;
//   }

//   return children;
// }
