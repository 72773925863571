import axios from "axios";
import { ETHERSCAN_API_KEY } from "../config";
import moment from "moment";

const A_DAY_MS = 24 * 60 * 60 * 1000;

export const getGasFeeAverage7DaysRecent = async () => {
  const currentUnixTime = Date.now();
  const sevenDaysAgo = currentUnixTime - 7 * A_DAY_MS;
  const rs = await getGasFeeAverageByDate(sevenDaysAgo, currentUnixTime);
  if (rs?.status === "1") {
    return rs?.result?.ProposeGasPrice || 0;
  }
  return 0;
};

export async function getGasFeeAverageByDate(startDate, endDate) {
  const url = `https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=${ETHERSCAN_API_KEY}`;
  console.log("moment(startDate).unix(): ", moment(startDate).unix());
  const params = {
    startts: moment(startDate).unix(),
    endts: moment(endDate).unix(),
  };

  try {
    const response = await axios.get(url, { params });
    const data = response.data;
    // Extract and process the gas fee information
    return data;
  } catch (error) {
    console.error(error);
  }
}

// const startDate = new Date('2023-12-01');
// const endDate = new Date('2023-12-09');

// getGasFee(startDate, endDate).then(data => {
//   // Process the data
//   console.log(data);
// });
