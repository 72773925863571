import axios from "axios";
import { TATUM_MAINNET } from "../configs";

export const getGasFeeTatum = async () => {
  try {
    const response = await axios.get(
      "https://api.tatum.io/v3/blockchain/fee/ETH",
      {
        headers: {
          "x-api-key": TATUM_MAINNET,
        },
      }
    );

    if (!response?.data?.medium) {
      return 0;
    }

    const GWEI = Math.pow(10, 9);
    const medium = Math.round(response?.data?.medium / GWEI);

    return medium;
  } catch (error) {
    console.log("error:000 ", error);
    return 0;
  }
};
