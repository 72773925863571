import { values } from "lodash";
import React from "react";
import { getDomainFromUrl } from "../../utils/url";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";
import { toast } from "react-toastify";
import { deleteWeb } from "../../apis/plan/web";
import { SPACE } from "./constants";

function ListWeb({ webs, setWebs, webType, extradataKey }) {
  const { user: activeUser } = useLoginGoogle();
  const uid = activeUser?.uid;
  if (!webs) return null;

  const clickDeleteWeb = async (id) => {
    if (!uid) {
      return toast.error("No uid");
    }
    if (!id) {
      return toast.error("No id");
    }
    const resData = await deleteWeb(id, uid);
    if (resData?.success) {
      toast.success("Delete block successful!");
      const newBlockList = webs?.filter((b) => b._id !== id);
      setWebs(newBlockList);
    } else {
      toast.error("Delete block failed!");
    }
  };

  const renderWeb = (web, swapKey) => {
    // console.log("web: ", web);
    const strChain = web?.extradata?.chain || "NONE";
    // const usefulType = web?.extradata?.usefulType || "NONE";
    return (
      <div className="flex items-center p-2" key={swapKey}>
        <span>
          {strChain}:{SPACE}
        </span>
        <span className="mx-1">
          {web?.icon ? (
            <img className="w-[16] h-4" src={web?.icon} alt="icon" />
          ) : (
            ""
          )}
        </span>
        <a className="ml-1" href={web?.url} target="_blank" rel="noreferrer">
          {getDomainFromUrl(web?.url)}
        </a>
        <span
          className="text-center w-14 mx-2 px-2  rounded-md bg-blue-500 text-white cursor-pointer text-xs"
          onClick={() => clickDeleteWeb(web?._id)}
        >
          {" "}
          Delete{" "}
        </span>
      </div>
    );
  };

  return (
    <div className="flex flex-col ml-2">
      {values(webType)
        .map((u) => u.key)
        .filter((u) => u !== "NONE")
        ?.map((usefulType, index) => {
          return (
            <div className="flex flex-col">
              {" "}
              <div>{usefulType}</div>
              <div className="ml-2">
                {webs
                  ?.filter((b) => b.extradata?.[extradataKey] === usefulType)
                  ?.map(renderWeb)}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ListWeb;
