import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  AspectRatio,
  Stack,
  Center,
  Heading,
  Text,
  HStack,
  Input,
  Divider,
  Button,
  useTheme,
  useColorMode,
  Modal,
} from "native-base";
import TimeAgo from "react-timeago";
import _ from "lodash";
import HeadingCard from "./Edit/HeadingCard";
import SubHeadingCard from "./Edit/SubHeadingCard";
import DescriptionCard from "./Edit/DescriptionCard";
import HeaderImageCard from "./Edit/HeaderImageCard";
import CompletedTimeCard from "./Edit/CompletedTimeCard";
import { stringClean } from "../../utils/string";
import { useLoginGoogle } from "../../Provider/GoogleAuthContext";
import { COLOR_CARD_DEFAULT, TAG_TYPES } from "./constants";
import { toast } from "react-toastify";
import {
  useCreateChallengeMutation,
  useUpdateChallengeMutation,
} from "../../store/services/challenge";
import { useDispatch, useSelector } from "react-redux";
import { editChallenge, removeAddChallenge } from "../../store/challangeSlice";
import StartTimeHeadingCard from "./Edit/StartTimeHeadingCard";
import moment from "moment";
import TagMUI from "../../elements/MUI/TagMUI";
import LoadingPageComponent from "../../ReusedComponents/LoadingComponent";
import {
  FILE_TYPE,
  getFileTypeBaseOnMime,
  getMimeTypeByFileName,
} from "../../utils/file";

const CardEmpty = (props) => {
  const dispatch = useDispatch();

  const [createChallenge, { isLoading }] = useCreateChallengeMutation();
  const [updateChallenge, { isLoading: updateLoading }] =
    useUpdateChallengeMutation();
  const { card = {}, isOpen, isEdit } = props || {};
  const {
    cid,
    name,
    description,
    image,
    completedTime,
    color,
    startTime,
    tags: cTags,
    video,
  } = card;
  const cgid = card?.group?.cgid;

  const [newHeading, setNewHeading] = useState(name);
  const [newDescription, setNewDescription] = useState(description);
  const [previewImage, setPreviewImage] = useState({
    name: image || video,
    type: getMimeTypeByFileName(video),
  });
  const [newCompletedTime, setNewCompletedTime] = useState(completedTime);
  const [newStartTime, setNewStartTime] = useState(startTime);
  const [cardColor, setCardColor] = useState(color);
  const [editElement, setEditElement] = useState(null);
  const [tags, setTags] = useState([]);

  const resetEmpty = () => {
    setNewHeading(name);
    // setNewSubHeading(subHeading);
    setNewDescription(description);
    setPreviewImage({ name: image, type: getMimeTypeByFileName(image) });
    setNewStartTime(Date.now);
    setNewCompletedTime(completedTime);
    setCardColor(COLOR_CARD_DEFAULT);
    setTags([]);
  };

  const setShowModal = (isOpen) => {
    if (!isOpen) {
      dispatch(removeAddChallenge());
    }
  };

  const { user } = useLoginGoogle();

  const createCard = async () => {
    setEditElement(null);

    if (!previewImage) {
      toast.error("Không có ảnh");
      return;
    }

    if (!cgid) {
      toast.error("Không có ID");
      return;
    }

    if (!isOpen) {
      toast.error("Không open empty card");
      return;
    }

    const dStartTime = moment(newStartTime).toDate();
    let dCompletedTime = moment(newCompletedTime).toDate();
    // console.log("dCompletedTime: ", dCompletedTime);
    // console.log("newCompletedTime: ", newCompletedTime);
    // console.log("newStartTime: ", newStartTime);
    if (moment(newCompletedTime).valueOf() < moment(newStartTime).valueOf()) {
      dCompletedTime = dStartTime;
      // console.log("dCompletedTime:2 ", dCompletedTime);
    }

    const fileType = getFileTypeBaseOnMime(previewImage?.type);

    if (isEdit) {
      const updatedCard = {
        cid,
        cgid,
        name: stringClean(newHeading),
        // type: stringClean(newSubHeading),
        description: stringClean(newDescription),
        image:
          fileType === FILE_TYPE.IMAGE ? stringClean(previewImage?.name) : "",
        video:
          fileType === FILE_TYPE.VIDEO ? stringClean(previewImage?.name) : "",
        audio:
          fileType === FILE_TYPE.AUDIO ? stringClean(previewImage?.name) : "",
        startTime: dStartTime,
        completedTime: dCompletedTime,
        color: cardColor,
        uid: user?.uid,
        tags: tags?.map((t) => t?.value) || [],
      };
      // return;
      console.log("updatedCard: ", updatedCard);
      // return;
      const resUpdatedAxios = await updateChallenge(updatedCard);
      // console.log("resUpdatedAxios: ", resUpdatedAxios);
      if (resUpdatedAxios?.data?.success) {
        toast.success(`Update challenge success`);
        resetEmpty();
        dispatch(removeAddChallenge());
      } else {
        toast.error("Update challenge failed");
      }
    } else {
      const newCard = {
        cgid,
        name: stringClean(newHeading),
        // type: stringClean(newSubHeading),
        description: stringClean(newDescription),
        image:
          fileType === FILE_TYPE.IMAGE ? stringClean(previewImage?.name) : "",
        video:
          fileType === FILE_TYPE.VIDEO ? stringClean(previewImage?.name) : "",
        audio:
          fileType === FILE_TYPE.AUDIO ? stringClean(previewImage?.name) : "",
        startTime: dStartTime,
        completedTime: dCompletedTime,
        color: cardColor,
        uid: user?.uid,
        tags: tags?.map((t) => t?.value) || [],
      };
      console.log("newCard: ", newCard);
      // return;
      const resAxios = await createChallenge(newCard);
      console.log("resAxios: ", resAxios);
      if (resAxios?.data?.success) {
        toast.success(`Create challenge success`);
        resetEmpty();
        dispatch(removeAddChallenge());
      } else {
        toast.error("Create challenge failed");
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        resetEmpty();
        setShowModal(false);
      }}
      style={{ height: "100vh", marginTop: window.scrollY }}
    >
      <Box alignItems="center">
        <Box
          rounded="lg"
          borderColor="coolGray.200"
          borderWidth="1"
          _dark={{
            borderColor: "coolGray.600",
            backgroundColor: "white",
          }}
          _web={{
            shadow: 2,
            borderWidth: 0,
          }}
          _light={{
            backgroundColor: "gray.50",
          }}
          width={240}
        >
          <HeaderImageCard
            {...{
              cardColor,
              setCardColor,
              setShowModal,
              previewImage,
              setPreviewImage: (data) => {
                // console.log("data: ", data);
                setPreviewImage(data);
                // if (data?.name && data?.type && data?.name !== name) {
                //   setHasLoading(true);
                // }
              },
              editElement,
              setEditElement,
            }}
          />
          <Stack p="4" space={3} bg={cardColor}>
            <HeadingCard
              {...{
                newHeading,
                setNewHeading,
                editElement,
                setEditElement,
              }}
            />
            <DescriptionCard
              {...{
                newDescription,
                setNewDescription,
                editElement,
                setEditElement,
              }}
            />
            <HStack zIndex={100}>
              <TagMUI
                data={TAG_TYPES}
                {...{ initTags: cTags, tags, setTags }}
              />
            </HStack>
            <HStack alignItems="center" justifyContent="space-between">
              <StartTimeHeadingCard
                {...{
                  newStartTime,
                  setNewStartTime,
                  editElement,
                  setEditElement,
                }}
              />
              <CompletedTimeCard
                {...{
                  newCompletedTime,
                  setNewCompletedTime,
                  editElement,
                  setEditElement,
                }}
              />
            </HStack>
            <Divider my="0" bg="light.100" />
            <Center>
              <Button
                size="sm"
                backgroundColor="light.100"
                onPress={createCard}
                _text={{
                  color: "gray.700",
                }}
              >
                Save
              </Button>
            </Center>
          </Stack>
        </Box>
      </Box>
      <LoadingPageComponent isLoading={isLoading} />
    </Modal>
  );
};

export default CardEmpty;
