function getColorFromIndex(index) {
  if (index < 0 || index > 100) {
    return;
  }

  // Map the index to a color range
  if (index < 20) {
    return "rgb(234, 57, 67)"; // Fear (low index)
  } else if (index >= 20 && index < 40) {
    return "rgb(234, 140, 0);"; // Neutral (medium index)
  } else if (index >= 40 && index < 60) {
    return "rgb(243, 212, 47)"; // Neutral (medium index)
  } else if (index >= 60 && index < 80) {
    return "rgb(147, 217, 0)"; // Neutral (medium index)
  } else {
    return "rgb(22, 199, 132)"; // Greed (high index)
  }
}

const IndexCryptoComponent = (props) => {
  const { gasFee, fearOfGreedIndex, gasFeeAverage } = props || {};
  return (
    <>
      {gasFee && fearOfGreedIndex && gasFeeAverage && (
        <div
          style={{
            backgroundColor: "#001524",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {gasFee && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: 16,
                width: "33%",
              }}
            >
              <h4
                style={{
                  color: "#fff",
                  // minHeight: 74,
                  textAlign: "center",
                }}
              >
                Gas Fee Etherium
              </h4>
              <div
                style={{
                  backgroundColor: "#3E7CCF",
                  width: 64,
                  height: 64,
                  borderRadius: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    fontSize: 30,
                  }}
                >
                  {gasFee}
                </div>
              </div>
            </div>
          )}
          {gasFee && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: 16,
                width: "33%",
                minHeight: 74,
              }}
            >
              <h4
                style={{
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Gas Fee Average
              </h4>
              <div
                style={{
                  backgroundColor: "#D864A9",
                  width: 64,
                  height: 64,
                  borderRadius: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    fontSize: 30,
                  }}
                >
                  {gasFeeAverage}
                </div>
              </div>
            </div>
          )}
          {fearOfGreedIndex && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: 16,
                width: "33%",
                minHeight: 74,
              }}
            >
              <h4
                style={{
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                {fearOfGreedIndex?.value_classification}
              </h4>
              <div
                style={{
                  backgroundColor:
                    getColorFromIndex(parseInt(fearOfGreedIndex?.value)) ||
                    "#fff",
                  width: 64,
                  height: 64,
                  borderRadius: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    fontSize: 30,
                  }}
                >
                  {fearOfGreedIndex?.value}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default IndexCryptoComponent;
