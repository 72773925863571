import { SERVER } from "../../configs/api";
import { wrapAxios } from "../wrapApi";

export const getAllWeb = (uid) => {
  return wrapAxios(`${SERVER}/plan/web/getAll`, {
    uid,
  });
};

export const deleteWeb = (id, uid) => {
  return wrapAxios(`${SERVER}/plan/web/delete`, {
    id,
    uid,
  });
};
